

export const contractConfigs = {
	"generic": {
		"abi": require("../abis/genABI-dai.json"),
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D"
	},
	"chef": {
		"abi": require("../abis/chef.json"),
		"address": "0x179CF583b6BC35ce8E5E3534F43D9a5Fa748617a"
	}
}

export const poolConfigs = {
	"0": {
		"stake-name": "munnyftm",
		"fee": "0",
		"name": "MUNNY-FTM LP",
		"rate": "4000",
		"dex": "spooky",
		"action": "add",
		"pairA": "FTM",
		"pairB": "0x195fe0c899434fb47cd6c1a09ba9da56a1cca12c",
		"status": "open",
		"type":"perm"
		}, 
	"1": {
		"stake-name": "eyeftm",
		"fee": "0",
		"name": "EYE-FTM LP",
		"rate": "0",
		"dex": "spooky",
		"action": "add",
		"pairA": "FTM",
		"pairB": "0x496e1693a7b162c4f0cd6a1792679cc48ecbcc8d",
		"status": "open",
		"type":"limited"
	}

}

export const rewardToken = {
	"name": "quanta",
	"displayname": "QUANTA",
	"symbol": "QUANTA",
	"address": "0x010226C777E27e04AE31d4CDDE9510863f5f1Aaa",
	"icon": require("../images/token-icons/quanta.png")
}

export const tokenConfigs = {
	"eyeftm": {
		"name": "EYE-FTM LP",
		"address": "0xaf6125d25c154cb23a28a1c3dd85b7d424a41d6e",
		"icon": require("../images/token-icons/eyeftm.png"),
		"decimals": 18
	},
	"munnyftm": {
		"name": "MUNNY-FTM LP",
		"address": "0x1a0119861a7af6af24fea78de5b3b5df9e0c7881",
		"icon": require("../images/token-icons/munnyftm.png"),
		"decimals": 18
	},
}