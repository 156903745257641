import { contractConfigs, rewardToken} from './components/contractConfigs.js';
import { scannerBaseURLByChain } from './components/ChainTools.js'
import { DappSection } from './DappSection.js'
const CHEF = contractConfigs["chef"]["address"]

const GdeliLinks = () => (
    <div>
        <a href="https://deliland.io" target="_blank">Deliland</a>
        <a href="https://salt.maxus.blog" target="_blank">Salt</a>
        <a href="https://water.maxus.blog" target="_blank">Water</a>
        <a href="https://acres.maxus.blog" target="_blank">Acres</a>
        <a href="https://yomopu.maxus.blog" target="_blank">Yomopu</a>
        <a href="https://animalfarm.deliland.io" target="_blank">Animal Farm</a>
    </div>
    )

const FarmgodLinks = () => (
    <div>
        <a href="https://app.gitbook.com/@maxusog/s/maxus-multiverse/" target="_blank">Docs</a>
        <a href="https://snapshot.org/#/farmgod.eth" target="_blank">Governance</a>
        <a href="https://echo.maxus.blog" target="_blank">ECHO</a>      
        <a href="https://boba.fi" target="_blank">Boba</a>
        <a href="https://eyeoftruth.io" target="_blank">Truth</a>
        <a href="https://maxus.blog" target="_blank">Multiverse Wallet</a>
    </div>
)

const XdaiLinks = () => (
    <div>
        <a href="https://moonwasp.io" target="_blank">MOONWASP</a>
        <a href="https://tequilafarm.io" target="_blank">Tequila Farm</a>
    </div>
    )


const SwapLinks = () => (
    <div>
        <a href="https://spookyswap.finance/#/" target="_blank">SpookySwap</a>
        
    </div>
        )

export const Footer = (props) => (
    <DappSection w={window.innerWidth} h="48" morph={{p:3, newP: "p(3*xMax/4,yMax)"}}>
        <a href={"https://ftmscan.com/address/" + CHEF + "#code"} target="_blank">TheChef</a>
        <GdeliLinks />
        <FarmgodLinks />
        <SwapLinks />
        <XdaiLinks />
        <p>This app is in like super-duper-early-pre-alpha mode. PUHHLLLEASSEEE do not ape your life savings into this. Use at your own risk, homie. Def not audited.</p>
      </DappSection>
    )