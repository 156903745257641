import React from 'react'

export const Header = (props) => {
	const [statsOn, setStatsOn] = React.useState(false)
	const toggleStats = () => {
		if (statsOn) {
		  setStatsOn(false)
		} else {
		  setStatsOn(true)
		}
	}
    return (
    	<header className="App-header">
    	<img src={props.rewardToken} className="App-logo" alt="logo" /> 
    	<div className="App-name">{(props.dappName) ? props.dappName : props.rewardTokenName}</div> 
    	<button className="toggle-stats" onClick={toggleStats}>Stats</button>
    	<div className={"stats TOGGLE__stats--" + statsOn}>
    		
    		<p>Emission Rate: {props.emissionRate} {props.rewardTokenName}/Block</p>
        	<p>Current Supply: {props.totalSupply} {props.rewardTokenName}</p>
        	<p>Balance: {props.rewardBalance} {props.rewardTokenName}</p>
         
            {(props.pctMax) ? <p>Current Supply % of Max: {props.pctMax} %</p> : ""}
    	</div>
    </header>
    )
      }