import { contractConfigs, poolConfigs, rewardToken } from './components/contractConfigs.js';
import { BigNumber } from '@ethersproject/bignumber';
import { fromAddressTo, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from './tokenUtilities.js'
const CHEF = contractConfigs["chef"]["address"]
const CHEFABI = contractConfigs["chef"]["abi"]
const GEN = contractConfigs["generic"]["abi"]

// Pu aka Pool Utilities

export class Pu {
	constructor(web3, ref) {
		this.web3 = web3
		this.ref = ref
		this.dec18 = BigNumber.from(10).pow(18)
	}


	cleanInputAmount (amt, addy, approve=false, isRetry=false) {
		console.log("triggered cleaning" + tokenNameAddyMap.get(addy).name, amt)
		let decNum = fromTokenNameToDecimals(tokenNameAddyMap.get(addy).name)
		let dec = BigNumber.from(10).pow(decNum)
		let cleanInputAmt = amt.toString();
		cleanInputAmt = cleanInputAmt.replaceAll(",","")
		let adjustor = (approve) ? 1 : -1

		let regDot = /[.]/g
		let locationOfDecimal = cleanInputAmt.search(regDot)

		let cleanInput;
		if (locationOfDecimal === -1) {
			cleanInput = cleanInputAmt + "0".repeat(decNum)
		} else {
			// cocksucking decimal time
			let inputIntegerString = cleanInputAmt.substring(0, locationOfDecimal);
			let inputDecimalString = cleanInputAmt.substring(locationOfDecimal + 1);
			console.log("integer : " + inputIntegerString)
			console.log("decimal : " + inputDecimalString)
			if (decNum > 0) { // this excludes ZOO from the adjustment
				if (inputDecimalString.length === decNum) {
					// probably hit max button
					cleanInput = inputIntegerString + inputDecimalString
				} else {
					// otherwise see how many decimal places are missing
					let decsMissing = decNum - inputDecimalString.length;

					// and add them
					cleanInput = inputIntegerString + inputDecimalString + "0".repeat(decsMissing)
				}
			} else {

			}
			
		}

		cleanInput = cleanInput.replace(/^0+/,'')
		let lastDigit
		if (isRetry) {
			
			lastDigit = cleanInput.substring(cleanInput.length - 1, cleanInput.length)
			//console.log(lastDigit)
			cleanInput = cleanInput.substring(0,cleanInput.length - 1)
			//console.log(cleanInput)
			lastDigit = (Number(lastDigit) > 0 ) ? (Number(lastDigit) - 1).toString() : "0"
			cleanInput = cleanInput + lastDigit
		}
		if (approve) console.log("approving " + cleanInput)
		if (!approve) console.log("depositing/withdrawing " + cleanInput)
		return cleanInput;
	}

	approveAmount (addy, callback)  {
		console.log("triggered approval")
		const tract = new this.web3.eth.Contract(GEN, addy);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, addy, true)
		tract.methods.approve(CHEF, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	checkAllowance (addy, callback)  {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Checking Allowance")

		tract.methods.allowance(window.ethereum.selectedAddress, CHEF)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}


	getBalance (addy, callback) {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Getting Balance")

		tract.methods.balanceOf(window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	depositAmount (poolId, callback) {
		console.log("triggered deposit " + poolId)
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		let cleanInput = this.cleanInputAmount(this.ref.current.value, fromTokenNameToAddress(poolConfigs[poolId.toString()]["stake-name"]))
		tract.methods.deposit(poolId, cleanInput)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	harvest (poolId, callback)  {
		console.log("triggered harvest")
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.deposit(poolId, 0)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	withdrawAmount (poolId, callback) {
		console.log("triggered withdraw")
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);

		const cleaner = (isRetry) => (this.cleanInputAmount(this.ref.current.value, fromTokenNameToAddress(poolConfigs[poolId.toString()]["stake-name"]),false,isRetry))
		let cleanInput = this.cleanInputAmount(this.ref.current.value, fromTokenNameToAddress(poolConfigs[poolId.toString()]["stake-name"]))
		console.log("withdrawAmount: ",cleanInput)
		tract.methods.withdraw(poolId, cleanInput).estimateGas({from: window.ethereum.selectedAddress},
			(error, gasAmount) => {
				
				if (!error) {
					tract.methods.withdraw(poolId, cleanInput)
						.send({from: window.ethereum.selectedAddress})
						.then(callback)
				} else {
					console.log("gas callback")
					cleanInput = cleaner(true)
					tract.methods.withdraw(poolId, cleanInput)
						.send({from: window.ethereum.selectedAddress})
						.then(callback)
				}
			})
			
	}

	triggerApproval(addy, callback) {
	
		this.approveAmount(
			addy, 
			() => {
				this.checkAllowance(
					addy, 
					callback
				)
				
			}
		)
				
	}

	getPoolBalance (poolId, callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);

		tract.methods.userInfo(poolId, window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	getPoolInfo(poolId, callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);

		tract.methods.poolInfo(poolId, window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	getRate(callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		//tract.methods.rewardtokenPerBlock()
		tract.methods.quantaPerSecond()
			.call({from: window.ethereum.selectedAddress})
					.then(callback)
	}
	getTotalAlloc(callback) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		tract.methods.totalAllocPoint()
			.call({from: window.ethereum.selectedAddress})
					.then(callback)
	}

	getPendingRewards (poolId, callback, isRequest) {
		const tract = new this.web3.eth.Contract(CHEFABI, CHEF);
		if (!isRequest) {
			//tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress)
			tract.methods.pendingQUANTA(poolId, window.ethereum.selectedAddress)
				.call({from: window.ethereum.selectedAddress})
					.then(callback)	
		} else {
			//return tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress)
			return tract.methods.pendingQUANTA(poolId, window.ethereum.selectedAddress)
						.call.request({from: window.ethereum.selectedAddress}, callback)	
		}
		
	}

	


	totalSupply (callback) {
		const tract = new this.web3.eth.Contract(contractConfigs["generic"]["abi"], rewardToken["address"])

		tract.methods.totalSupply().call({from: window.ethereum.selectedAddress})
			.then(callback)
	}
	rewardBalance (callback) {
		const tract = new this.web3.eth.Contract(contractConfigs["generic"]["abi"], rewardToken["address"])

		tract.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
			.then(callback)
	}

}

