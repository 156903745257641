// Author: Ianoda aka Maxus -- maxus.blog
import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';
import { Footer } from './footer.js';
import { InputView } from './inputview.js'
import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress } from './tokenUtilities.js'
import { Header } from './header.js'
// CUSTOM components 
import './App.scss';
import { Pool } from './components/PoolElement.js';
import { chainMap, enforceChain } from './components/ChainTools.js';
import { contractConfigs, poolConfigs, rewardToken, tokenConfigs } from './components/contractConfigs.js';
import { Pu } from './PoolUtilities.js'
import anime from 'animejs/lib/anime.es.js';
import bg from './images/bg.png'
// MATH STUFF
const dec18 = BigNumber.from(10).pow(18)
const CHEF = contractConfigs["chef"]["address"]
const REWARD = rewardToken["address"]

const poolIDs = Object.keys(poolConfigs)

function handleChainChange(chainId) {
      window.location.reload();
    }


function App() {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])
  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----



  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetADDY, setTheTargetADDY] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetNAME, setTheTargetNAME] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the pool ID of the target pool
  const [theTargetPOOL, setTheTargetPOOL] = React.useState(0);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

  // this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // this is the web3 instance used throughout the dApp
  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')


//  END SCRIPT




  // implement Pu class (poolUtilities.js)
  var pu = new Pu(web3, theInputRef)

  // onClick function factory for building appropriate input overlay
  const openInputViewAndDoStuff = (addy, intent, argOneIsAddy = false) => {
  
    let theAddy = (argOneIsAddy) ? addy : fromTokenNameToAddress(poolConfigs[addy.toString()]["stake-name"])
    let theName = (argOneIsAddy) ? addy : poolConfigs[addy.toString()]["stake-name"]
    let thePool = (argOneIsAddy) ? addy : addy.toString()
    return () => {

      toggleInput()
      setTheTargetADDY(theAddy)
      setTheInputINTENT(intent)
      setTheTargetNAME(theName)
      
      setTheTargetPOOL(thePool)
      console.log(theTargetPOOL)
      pu.getBalance(theAddy, (bal) => smartSetBalanceOfTarget(bal))
      pu.checkAllowance(theAddy, (allowance) => smartSetAllowanceOfTarget(allowance))
    }

  }


  // onClick function factory that returns appropriate harvest function
  const returnHarvestFunction = (id) => {
    return () => {
      setIsPending(true)
      pu.harvest(
        id,
        () => {
          setIsPending(false)
          triggerGetPendingRewards(id);
        })
    }     
  }

  // onClick function for depositing to theTargetPOOL
  const triggerDeposit = () => {
    setIsPending(true)
    console.log("target pool: " + theTargetPOOL)
    pu.depositAmount(
      theTargetPOOL,
      () => {
        setIsPending(false)
        toggleInput();
        triggerGetPoolBalance(theTargetPOOL);
        triggerGetPendingRewards(theTargetPOOL);
      }
    )
  }

  // onClick function for withdrawing from theTargetPOOL
  const triggerWithdraw = () => {
    setIsPending(true)
    pu.withdrawAmount(
      theTargetPOOL,
      () => {
        setIsPending(false)
        toggleInput();
        triggerGetPoolBalance(theTargetPOOL);
        triggerGetPendingRewards(theTargetPOOL);
      }
    )
  }

  const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), eval(getStateString(theTargetPOOL, false)), false)
    } else {
      if (theTargetNAME == "echo") {
        if (balanceOfTarget / 10 ** 9 > 69420) {
          theInputRef.current.value = 69420
        } else {
          theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget, false)
        }
      } else {
        theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget, false)
      }
      
    }
    
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance)
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance)
    setAllowanceOfTarget(allowance)
  }

  // onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    pu.triggerApproval(
      theTargetADDY, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }

  // -- end of Logics
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----



  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Balances, Rewards, and Everything Else
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%----- 


  // every pool needs a balance and pendingReward state
  // the _name must be equivalent to the stake-name of the pool in contractConfigs.js

  const [poolBalance_munnyftm, setPoolBalance_munnyftm] = React.useState(0);
  const [pendingRewards_munnyftm, setPendingRewards_munnyftm] = React.useState(0);

  const [poolBalance_eyeftm, setPoolBalance_eyeftm] = React.useState(0);
  const [pendingRewards_eyeftm, setPendingRewards_eyeftm] = React.useState(0);


  // returns the string that when passed through eval(), will get or set the appropriate state
  const getStateString = (poolID, isSet = true, isBalance = true ) => {
    const prefix = (!isSet) ? "p" : "setP"
    const meat = (isBalance) ? "oolBalance" : "endingRewards"
    const suffix = "_" + poolConfigs[poolID.toString()]["stake-name"]
    return prefix + meat + suffix  
  }


  const triggerGetPoolBalance = (id) => {
    pu.getPoolBalance(
      id,
      (res) => {
        console.log(res)
        console.log("^^ pool balance")
        eval(getStateString(id, true, true) + "(res[0])")
      }
    )
  }
 

  const triggerGetPendingRewards = (id) => {
    pu.getPendingRewards(
      id,
      (res) => {
        console.log(res + " << should be rewards for pool >> "+id)
        eval(getStateString(id, true, false) + "(res)") 
      }
    )
  }




  const getAllRewards = () => { 
    let ids = Object.keys(poolConfigs)
    ids.forEach((x, index) => {
      triggerGetPendingRewards(index)
    })
  }


  const getAllPoolBalances = () => { 
    let ids = Object.keys(poolConfigs)
    ids.forEach((x, index) => {
      triggerGetPoolBalance(index)
    })
  }


  const letItRip = () => {
    getAllRewards()
    getAllPoolBalances()
    getEmissionRate()
    getTotalAlloc()
    getTotalSupply()
    getRewardBalance()
  }

  const [emissionRate, setTheEmissionRate] = React.useState(0)
       
  const getEmissionRate = () => {
    pu.getRate(
      (res) => {
        setTheEmissionRate(res)
      }
      )
  }

  const [totalAllocPoints, setTotalAllocPoints] = React.useState(0)
       
  const getTotalAlloc = () => {
    pu.getTotalAlloc(
      (res) => {
        setTotalAllocPoints(res)
      }
      )
  }

  const [totalSupply, setTotalSupply] = React.useState(0)

  const getTotalSupply = () => {
    pu.totalSupply((res) => {
      setTotalSupply(res)
    })
  }
  

  const [rewardBalance, setRewardBalance] = React.useState(0)

  const getRewardBalance = () => {
    pu.rewardBalance((res) => {
      setRewardBalance(res)
    })
  }


  
  // render the app you dumb motherfucker
  return (
    <div className={"App"}>

    <Header 
        dappName="The Quanta Company"
        rewardToken={rewardToken["icon"]["default"]} 
        pctMax={numeral(100 * (totalSupply / 10 ** 18) / (37 * 10 ** 9)).format("0.000")} 
        maxSupply={"37 b"}
        totalSupply={formatTokenBalance(18,totalSupply)}
        emissionRate={formatTokenBalance(18,emissionRate)} 
        rewardBalance={formatTokenBalance(18,rewardBalance)}
        rewardTokenName={rewardToken["name"]} />
      
        
      <button className="metamask-btn" onClick={connectMM}>{mmBtnText}</button>


      <div className="farmland">
        <p>Welcome to The Quanta Company, aka Quanta Co.! Here, you can earn QUANTA by staking your Multiverse Spooky LPs. QUANTA can be deposited at any Pub in the Multiverse. Pools with a purple border are Permanent. Pools with a white border are temporary.</p>
        {poolIDs.map(id => (
          <Pool
     
            chainId={window.ethereum.chainId}
            emissionRate={emissionRate}
            totalAllocPoints={totalAllocPoints} 
            poolID={id}
            balance={eval(getStateString(id, false, true))}
            rewards={eval(getStateString(id, false, false))}
            minusFunction = {openInputViewAndDoStuff(id, "remove")}
            plusFunction={openInputViewAndDoStuff(id, "add")} 
            harvestFunction={returnHarvestFunction(id)} />

          ))}
      
      </div>

      <InputView 
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), eval(getStateString(theTargetPOOL, false)))}
        balance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget)}
        allowance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />


      <div className={"pending pending--" + isPending}>Pending Transaction</div>

    <Footer />
   </div>
  );
}

export default App;
